import React from 'react';
import { motion, type HTMLMotionProps } from 'framer-motion';
import { cn } from '@/lib/utils';
import styles from './Card.module.css';

type CardVariant = 'default' | 'hover' | 'active';

type MotionDivProps = HTMLMotionProps<'div'>;
type StaticDivProps = React.HTMLAttributes<HTMLDivElement>;

interface CardProps extends Omit<MotionDivProps, 'animate'> {
  children: React.ReactNode;
  variant?: CardVariant;
  animate?: boolean;
}

const variantStyles: Record<CardVariant, string> = {
  default: 'border-zinc-800',
  hover: 'border-zinc-800 hover:border-zinc-700 transition-all duration-300',
  active: 'border-zinc-700',
};

const animationConfig = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: {
    duration: 0.3,
    ease: 'easeOut',
  },
};

const Card = ({
  children,
  variant = 'default',
  animate = false,
  className,
  ...props
}: CardProps) => {
  if (animate) {
    return (
      <motion.div
        className={cn(
          styles.card,
          'bg-zinc-900/95 border rounded-lg p-6',
          variantStyles[variant],
          className
        )}
        {...animationConfig}
        {...(props as MotionDivProps)}
      >
        {children}
      </motion.div>
    );
  }

  return (
    <div
      className={cn(
        styles.card,
        'bg-zinc-900/95 border rounded-lg p-6',
        variantStyles[variant],
        className
      )}
      {...(props as StaticDivProps)}
    >
      {children}
    </div>
  );
};

export default Card;
