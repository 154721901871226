import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { FaLock, FaSpinner } from 'react-icons/fa';
import PageLayout from '@/components/PageLayout';
import { supabase, signUpWithRedirect } from '@/lib/supabase';
import EmailVerificationMessage from './EmailVerificationMessage';
import PasswordStrengthIndicator from '@/components/ui/PasswordStrengthIndicator';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [signupComplete, setSignupComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [autoRedirectCount, setAutoRedirectCount] = useState<number | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isEmailConfirmation = params.get('type') === 'email_confirmation';
    const status = params.get('status');
    const countdown = params.get('countdown');
    const error = params.get('error');

    // Handle confirmation error
    if (error === 'confirmation_failed') {
      setError('There was a problem confirming your email. Please try signing up again.');
      setIsSignUp(true);
      window.history.replaceState({}, '', '/login');
      return;
    }

    // If this is a successful email confirmation
    if (isEmailConfirmation && status === 'success') {
      setConfirmationSuccess(true);
      // Start countdown if provided
      if (countdown) {
        setAutoRedirectCount(parseInt(countdown));
      }
      // Clean up URL without triggering a page reload
      window.history.replaceState({}, '', '/login');
      return;
    }

    // Regular auth state check - only redirect if not showing confirmation success
    if (user && !loading && !confirmationSuccess && !isEmailConfirmation) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    console.log('Countdown state:', autoRedirectCount);
    if (autoRedirectCount !== null && autoRedirectCount >= 0) {
      const timer = setTimeout(() => {
        if (autoRedirectCount === 0) {
          console.log('Countdown complete, redirecting...');
          navigate('/', { replace: true });
        } else {
          console.log('Countdown tick:', autoRedirectCount - 1);
          setAutoRedirectCount(autoRedirectCount - 1);
        }
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [autoRedirectCount, navigate]);

  const validateEmail = (email: string): string => {
    if (!email) return 'Email is required';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return 'Please enter a valid email address';
    return '';
  };

  const validatePassword = (password: string): string => {
    if (!password) return 'Password is required';
    if (isSignUp) {
      if (password.length < 6) return 'Password must be at least 6 characters';
    }
    return '';
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'email' | 'password'
  ) => {
    const value = e.target.value;
    setError(null);
    if (field === 'email') {
      setEmail(value);
      const error = validateEmail(value);
      setValidationErrors((prev) => ({ ...prev, email: error }));
    } else {
      setPassword(value);
      const error = validatePassword(value);
      setValidationErrors((prev) => ({ ...prev, password: error }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      if (isResetMode) {
        const { error } = await supabase.auth.resetPasswordForEmail(email, {
          redirectTo: `${window.location.origin}/reset-password`,
        });
        if (error) throw error;
        setResetSent(true);
        return;
      }

      if (isSignUp) {
        await handleSignUp(e);
        return;
      }

      // Regular sign in
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        if (error.message.includes('Invalid login credentials')) {
          setError('Invalid email or password');
        } else if (error.message.includes('Email not confirmed')) {
          setError('Please verify your email address before logging in');
        } else {
          throw error;
        }
        return;
      }

      // Let the useEffect handle navigation after successful sign in
    } catch (err) {
      console.error('Auth error:', err);
      setError(
        err instanceof Error ? err.message : 'An unexpected error occurred'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await signUpWithRedirect(email, password);
      
      if (error) throw error;
      
      if (data) {
        setSignupComplete(true);
        setError(null);
      }
    } catch (error: any) {
      console.error('Sign up error:', error);
      setError(error.message || 'An error occurred during sign up');
      setSignupComplete(false);
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    if (signupComplete) {
      return <EmailVerificationMessage type="signup" email={email} />;
    }

    if (resetSent) {
      return <EmailVerificationMessage type="reset" email={email} />;
    }

    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => handleInputChange(e, 'email')}
            disabled={isLoading}
            className={cn(
              'w-full p-2 border rounded',
              validationErrors.email && 'border-red-500'
            )}
          />
          {validationErrors.email && (
            <p className="mt-1 text-sm text-red-500">{validationErrors.email}</p>
          )}
        </div>

        {!isResetMode && (
          <div>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => handleInputChange(e, 'password')}
              disabled={isLoading}
              className={cn(
                'w-full p-2 border rounded',
                validationErrors.password && 'border-red-500'
              )}
            />
            {validationErrors.password && (
              <p className="mt-1 text-sm text-red-500">
                {validationErrors.password}
              </p>
            )}
            {isSignUp && <PasswordStrengthIndicator password={password} />}
          </div>
        )}

        {error && <div className="text-red-500 text-sm">{error}</div>}

        <Button
          type="submit"
          disabled={isLoading}
          className="w-full flex items-center justify-center"
        >
          {isLoading ? (
            <FaSpinner className="animate-spin mr-2" />
          ) : (
            <FaLock className="mr-2" />
          )}
          {isResetMode
            ? 'Send Reset Link'
            : isSignUp
            ? 'Sign Up'
            : 'Log In'}
        </Button>

        <div className="text-sm">
          {!isResetMode && !signupComplete && (
            <div className="mt-6 text-center">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-zinc-600"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-zinc-800 text-zinc-400">
                    {isSignUp ? 'Already have an account?' : 'New to FanFabric?'}
                  </span>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsSignUp(!isSignUp);
                  setError(null);
                }}
                className="mt-4 text-orange-500 hover:text-orange-400 font-medium"
              >
                {isSignUp ? 'Sign in now' : 'Create an account'}
              </button>
            </div>
          )}
          {!isSignUp && !signupComplete && (
            <button
              type="button"
              onClick={() => {
                setIsResetMode(!isResetMode);
                setError(null);
              }}
              className="mt-4 text-sm text-zinc-400 hover:text-zinc-300 block w-full text-center"
            >
              {isResetMode ? 'Back to login' : 'Forgot your password?'}
            </button>
          )}
        </div>
      </form>
    );
  };

  const [validationErrors, setValidationErrors] = useState<{
    email?: string;
    password?: string;
  }>({});

  if (confirmationSuccess) {
    return (
      <PageLayout>
        <div className="max-w-md mx-auto mt-8 p-6 bg-zinc-800 rounded-lg shadow-lg">
          <div className="text-center">
            <div className="mb-4 text-green-500">
              <svg
                className="w-16 h-16 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-4 text-white">Email Verified Successfully!</h2>
            <p className="text-zinc-300 mb-4">
              Thank you for verifying your email. You can now access all features of FanFabric.
            </p>
            {autoRedirectCount !== null && (
              <p className="text-sm text-zinc-400">
                Redirecting to homepage in {autoRedirectCount} seconds...
              </p>
            )}
            <Button
              onClick={() => navigate('/', { replace: true })}
              className="mt-6"
            >
              Go to Homepage
            </Button>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className="flex items-center justify-center min-h-[calc(100vh-4rem)]">
        <div className="w-full max-w-md space-y-8">
          <div className="w-full p-6 bg-zinc-800 rounded-lg shadow-lg">
            {loading ? (
              <div className="flex justify-center py-8">
                <FaSpinner className="animate-spin h-8 w-8 text-orange-500" />
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center mb-6">
                  <FaLock className="w-6 h-6 text-orange-500 mr-2" />
                  <h2 className="text-2xl font-bold text-orange-500">
                    {isResetMode ? 'Reset Password' : isSignUp ? 'Create Account' : 'Welcome Back'}
                  </h2>
                </div>

                {error && (
                  <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">
                    {error}
                  </div>
                )}

                {!confirmationSuccess && renderForm()}
              </>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Login;
