import React from 'react';

const Logo: React.FC = () => {
  return (
    <div className="relative w-11 h-11 overflow-hidden rounded-lg">
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-orange-600/60 to-transparent animate-mist-flow"></div>
      <span className="flex items-center justify-center w-full h-full border-2 border-orange-500 rounded-lg bg-zinc-800 bg-opacity-50 text-white font-bold text-3xl relative z-10">
        F<sup className="text-xl relative -top-1 text-orange-500">2</sup>
      </span>
    </div>
  );
};

export default Logo;
