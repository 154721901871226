import { createClient } from '@supabase/supabase-js';
import type { User } from '@/types/Admin';
import type { Product } from '@/types/Store';
import type { TeamCreateInput, TeamUpdateInput } from '@/types/Team';
import { handleSupabaseError } from '@/lib/api/utils/errorHandler';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabaseServiceRole = import.meta.env.VITE_SUPABASE_SERVICE_ROLE_KEY;

// Get the current site URL
const getSiteUrl = () => {
  // Check if we're in development mode
  const isDevelopment = window.location.hostname === 'localhost' || 
                       window.location.hostname.includes('192.168.') || 
                       window.location.port === '8888';
  
  if (isDevelopment) {
    return `${window.location.protocol}//${window.location.hostname}:8888`;
  }
  return import.meta.env.VITE_SITE_URL || 'https://fanfabric.com';
};

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Regular client for normal operations
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    flowType: 'pkce',
    autoRefreshToken: true,
    detectSessionInUrl: true,
    persistSession: true
  }
});

// Set up auth configuration after client creation
supabase.auth.setSession({
  access_token: '',
  refresh_token: '',
});

// Helper function for sign up that includes redirect
export const signUpWithRedirect = async (email: string, password: string) => {
  const redirectTo = `${getSiteUrl()}/auth/callback`;
  console.log('Signup redirect URL:', redirectTo); // Debug log
  
  return supabase.auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: redirectTo,
      data: {
        timestamp: new Date().toISOString()
      }
    }
  });
};

// Admin client with proper service role configuration
export const supabaseAdmin = createClient(supabaseUrl, supabaseServiceRole, {
  auth: {
    autoRefreshToken: false,
    persistSession: false,
    detectSessionInUrl: false,
  },
  global: {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${supabaseServiceRole}`
    }
  }
});

// User management functions
export async function getUsers(page: number, limit: number) {
  try {
    const {
      data: profilesData,
      error: profilesError,
      count,
    } = await supabaseAdmin
      .from('profiles')
      .select('id, role, created_at, is_suspended', { count: 'exact' })
      .range((page - 1) * limit, page * limit - 1);

    if (profilesError) {
      throw handleSupabaseError(profilesError, 'Failed to fetch users');
    }

    const { data: authData, error: authError } =
      await supabaseAdmin.auth.admin.listUsers();

    if (authError) {
      throw handleSupabaseError(authError, 'Failed to fetch auth users');
    }

    const users: User[] = (profilesData || []).map((profile) => {
      const authUser = authData.users.find((au) => au.id === profile.id);
      return {
        id: profile.id,
        email: authUser?.email || '',
        created_at: profile.created_at,
        role: profile.role as 'admin' | 'user',
        is_suspended: profile.is_suspended || false,
      };
    });

    return {
      users,
      total: count || 0,
    };
  } catch (error) {
    throw handleSupabaseError(error, 'Failed to fetch users');
  }
}

export async function updateUserRole(userId: string, role: 'admin' | 'user') {
  const { error } = await supabaseAdmin
    .from('profiles')
    .update({ role })
    .eq('id', userId);

  if (error) throw handleSupabaseError(error, 'Failed to update user role');
}

export async function updateUserEmail(userId: string, email: string) {
  const { error } = await supabaseAdmin.auth.admin.updateUserById(userId, {
    email,
  });
  if (error) throw handleSupabaseError(error, 'Failed to update user email');
}

export async function updateUserPassword(userId: string, password: string) {
  const { error } = await supabaseAdmin.auth.admin.updateUserById(userId, {
    password,
  });
  if (error) throw handleSupabaseError(error, 'Failed to update user password');
}

export async function deleteUser(userId: string) {
  const { error } = await supabaseAdmin.auth.admin.deleteUser(userId);
  if (error) throw handleSupabaseError(error, 'Failed to delete user');
}

// Dashboard functions
export async function getDashboardStats() {
  try {
    // Get products
    const { data: products, error: productsError } = await supabaseAdmin
      .from('products')
      .select('*');

    // Get orders
    const { data: orders, error: ordersError } = await supabaseAdmin
      .from('orders')
      .select('total_amount');

    // Get profiles
    const { data: profiles, error: profilesError } = await supabaseAdmin
      .from('profiles')
      .select('*');

    // Get teams
    const { data: teams, error: teamsError } = await supabaseAdmin
      .from('teams')
      .select('*');

    if (productsError || ordersError || profilesError || teamsError) {
      throw handleSupabaseError(
        productsError || ordersError || profilesError || teamsError,
        'Error fetching dashboard stats'
      );
    }

    // Calculate total revenue
    const totalRevenue =
      orders?.reduce(
        (sum: number, order: { total_amount: number }) =>
          sum + (Number(order.total_amount) || 0),
        0
      ) || 0;

    return {
      totalProducts: products?.length || 0,
      totalOrders: orders?.length || 0,
      totalUsers: profiles?.length || 0,
      totalRevenue,
      totalTeams: teams?.length || 0,
    };
  } catch (error) {
    throw handleSupabaseError(error, 'Failed to fetch dashboard stats');
  }
}

// Product functions
export async function getProducts() {
  console.log('Fetching products...');
  // Get products and join with categories table
  const { data: products, error } = await supabaseAdmin
    .from('products')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching products:', error);
    throw handleSupabaseError(error, 'Failed to fetch products');
  }

  console.log('Raw products data:', products);

  // Get all categories for name lookup
  const { data: categories, error: categoriesError } = await supabaseAdmin
    .from('categories')
    .select('id, name');

  if (categoriesError) {
    console.error('Error fetching categories:', categoriesError);
    throw handleSupabaseError(categoriesError, 'Failed to fetch categories');
  }

  console.log('Categories data:', categories);

  // Create a map of category IDs to names
  const categoryMap = Object.fromEntries(
    (categories || []).map(cat => [cat.id, cat.name])
  );

  console.log('Category map:', categoryMap);

  // Transform products to include category names
  const transformedData = products?.map(product => {
    const categoryName = categoryMap[product.category] || product.category;
    console.log('Product category mapping:', {
      productId: product.id,
      originalCategory: product.category,
      mappedCategory: categoryName
    });
    return {
      ...product,
      category: categoryName
    };
  });
  
  return transformedData;
}

export async function createProduct(
  product: Omit<Product, 'id' | 'created_at' | 'updated_at'>
) {
  const { data, error } = await supabaseAdmin
    .from('products')
    .insert(product)
    .select()
    .single();

  if (error) throw handleSupabaseError(error, 'Failed to create product');
  return data;
}

export async function deleteProduct(id: string) {
  const { error } = await supabaseAdmin.from('products').delete().eq('id', id);

  if (error) throw handleSupabaseError(error, 'Failed to delete product');
}

// Team functions
export async function getTeams() {
  try {
    const { data, error } = await supabase
      .from('teams')
      .select('*')
      .order('city', { ascending: true });

    if (error) throw handleSupabaseError(error, 'Failed to fetch teams');
    return data;
  } catch (error) {
    console.error('Error in getTeams:', error);
    throw error;
  }
}

export async function createTeam(team: TeamCreateInput) {
  try {
    // Remove any undefined values
    const cleanTeam = Object.fromEntries(
      Object.entries(team).filter(([_, v]) => v !== undefined)
    );

    const { data, error } = await supabase
      .from('teams')
      .insert([cleanTeam])
      .select()
      .single();

    if (error) throw handleSupabaseError(error, 'Failed to create team');
    return data;
  } catch (error) {
    console.error('Error in createTeam:', error);
    throw error;
  }
}

export async function updateTeam(id: string, updates: TeamUpdateInput) {
  try {
    // Remove any undefined values
    const cleanUpdates = Object.fromEntries(
      Object.entries(updates).filter(([_, v]) => v !== undefined)
    );

    const { data, error } = await supabase
      .from('teams')
      .update(cleanUpdates)
      .eq('id', id)
      .select()
      .single();

    if (error) throw handleSupabaseError(error, 'Failed to update team');
    return data;
  } catch (error) {
    console.error('Error in updateTeam:', error);
    throw error;
  }
}

export async function toggleTeamStatus(id: string, is_active: boolean) {
  const { data, error } = await supabaseAdmin
    .from('teams')
    .update({ is_active })
    .eq('id', id)
    .select()
    .single();

  if (error) throw handleSupabaseError(error, 'Failed to update team status');
  return data;
}

export async function deleteTeam(id: string) {
  const { error } = await supabaseAdmin.from('teams').delete().eq('id', id);

  if (error) throw handleSupabaseError(error, 'Failed to delete team');
}

// Category functions
export async function getCategories() {
  try {
    const { data, error } = await supabase
      .from('categories')
      .select('*')
      .order('name');

    if (error) {
      throw handleSupabaseError(error, 'Failed to fetch categories');
    }

    return data || [];
  } catch (error) {
    console.error('Error in getCategories:', error);
    throw error;
  }
}

export async function createCategory(category: { name: string; description?: string }) {
  try {
    const { data, error } = await supabase
      .from('categories')
      .insert([category])
      .select()
      .single();

    if (error) {
      throw handleSupabaseError(error, 'Failed to create category');
    }

    return data;
  } catch (error) {
    console.error('Error in createCategory:', error);
    throw error;
  }
}

export async function updateCategory(id: string, updates: { name?: string; description?: string }) {
  try {
    const { data, error } = await supabase
      .from('categories')
      .update(updates)
      .eq('id', id)
      .select()
      .single();

    if (error) {
      throw handleSupabaseError(error, 'Failed to update category');
    }

    return data;
  } catch (error) {
    console.error('Error in updateCategory:', error);
    throw error;
  }
}

export async function deleteCategory(id: string) {
  try {
    const { error } = await supabase
      .from('categories')
      .delete()
      .eq('id', id);

    if (error) {
      throw handleSupabaseError(error, 'Failed to delete category');
    }
  } catch (error) {
    console.error('Error in deleteCategory:', error);
    throw error;
  }
}
