import React, { useState } from 'react';
import PageLayout from '../components/PageLayout';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { FaEnvelope } from 'react-icons/fa';
import { cn } from '@/lib/utils'; // Add this import

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
    alert('Thank you for your message. We will get back to you soon!');
  };

  return (
    <PageLayout title="Contact Us" icon={FaEnvelope}>
      <div className="max-w-md mx-auto">
        <p className="mb-6 text-lg text-zinc-300">
          Have a question or comment? We'd love to hear from you. Please fill
          out the form below and we'll get back to you as soon as possible.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block mb-2 font-medium text-zinc-300"
            >
              Name
            </label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 font-medium text-zinc-300"
            >
              Email
            </label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block mb-2 font-medium text-zinc-300"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows={4}
              className={cn(
                'w-full px-4 py-3 bg-zinc-900 rounded-xl leading-none text-white text-lg',
                'border border-orange-500/40 focus:border-orange-500/60',
                'focus:outline-none focus:ring-2 focus:ring-orange-500/20',
                'transition-all duration-300',
                'placeholder:text-zinc-400'
              )}
            ></textarea>
          </div>
          <Button
            type="submit"
            variant="primary"
            className="w-full py-3 rounded-xl"
          >
            Send Message
          </Button>
        </form>
      </div>
    </PageLayout>
  );
};

export default Contact;
