export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  ABOUT: '/about',
  PRIVACY: '/privacy',
  CONTACT: '/contact',
  RESET_PASSWORD: '/reset-password',
  TEAMS: {
    LOCATION: '/products',
    TEAM: '/products/:location/:team',
  },
  ADMIN: {
    DASHBOARD: '/admin',
    TEAMS: '/admin/teams',
    TEAM_DETAILS: '/admin/teams/:teamId',
    PRODUCTS: '/admin/products',
    PRODUCT_DETAILS: '/admin/products/:productId',
    CATEGORIES: '/admin/categories',
    ORDERS: '/admin/orders',
    ORDER_DETAILS: '/admin/orders/:orderId',
    USERS: '/admin/users',
    SETTINGS: '/admin/settings',
  },
};

export const QUERY_KEYS = {
  TEAMS: 'teams',
  PRODUCTS: 'products',
  ORDERS: 'orders',
  USERS: 'users',
  CATEGORIES: 'categories',
};
