import React, { useState } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import {
  FaChartBar,
  FaBox,
  FaShoppingCart,
  FaUsers,
  FaBars,
  FaTimes,
  FaTags,
  FaLayerGroup,
  FaCog,
} from 'react-icons/fa';
import { ROUTES } from '../../../constants';

const AdminLayout: React.FC = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // Group navigation items by section
  const navigationSections = [
    {
      title: 'Overview',
      items: [
        { path: ROUTES.ADMIN.DASHBOARD, icon: FaChartBar, label: 'Dashboard' },
      ],
    },
    {
      title: 'Team Management',
      items: [
        { path: ROUTES.ADMIN.TEAMS, icon: FaLayerGroup, label: 'Teams' },
        {
          path: ROUTES.ADMIN.PRODUCTS,
          icon: FaBox,
          label: 'Products',
        },
      ],
    },
    {
      title: 'Store Management',
      items: [
        { path: ROUTES.ADMIN.ORDERS, icon: FaShoppingCart, label: 'Orders' },
        { path: ROUTES.ADMIN.CATEGORIES, icon: FaTags, label: 'Categories' },
      ],
    },
    {
      title: 'User Management',
      items: [
        { path: ROUTES.ADMIN.USERS, icon: FaUsers, label: 'Users' },
      ],
    },
    {
      title: 'Settings',
      items: [
        { path: ROUTES.ADMIN.SETTINGS, icon: FaCog, label: 'Settings' },
      ],
    },
  ];

  return (
    <div className="flex h-screen bg-zinc-900 overflow-hidden">
      {/* Backdrop overlay for mobile */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      {/* Sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 z-50 w-64 transform bg-zinc-800 transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0 ${
          isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex h-full flex-col">
          {/* Mobile menu close button */}
          <div className="flex items-center justify-between p-4 lg:hidden">
            <span className="text-lg font-semibold text-white">Menu</span>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="text-zinc-400 hover:text-white transition-colors"
            >
              <FaTimes className="h-6 w-6" />
            </button>
          </div>

          {/* Navigation */}
          <nav className="flex-1 space-y-8 px-4 py-4 overflow-y-auto">
            {navigationSections.map((section, index) => (
              <div key={index}>
                <h3 className="px-3 text-xs font-semibold uppercase tracking-wider text-zinc-400">
                  {section.title}
                </h3>
                <div className="mt-3 space-y-1">
                  {section.items.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      onClick={() => setIsMobileMenuOpen(false)}
                      className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg ${
                        isActive(item.path)
                          ? 'border-2 border-orange-500 text-orange-500'
                          : 'text-zinc-300 hover:bg-zinc-700'
                      }`}
                    >
                      <item.icon className="mr-3 h-5 w-5" />
                      {item.label}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </nav>
        </div>
      </aside>

      {/* Main content area */}
      <div className="flex-1 flex flex-col min-w-0">
        {/* Mobile header with menu button */}
        <div className="sticky top-0 z-40 flex items-center bg-zinc-800 lg:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(true)}
            className="p-4 text-zinc-400 hover:text-white transition-colors focus:outline-none"
          >
            <FaBars className="h-6 w-6" />
          </button>
          <div className="flex-1 text-center">
            <span className="text-lg font-semibold text-white">Admin Portal</span>
          </div>
          <div className="w-14"></div> {/* Spacer for alignment */}
        </div>

        {/* Main content */}
        <div className="flex-1 overflow-auto">
          <div className="container mx-auto px-4 py-8">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
