// MainContent.tsx
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TeamSelector from './TeamSelector';
import TeamRequestForm from './TeamRequestForm';
import { Team } from '../types/Team';
import Typography from './ui/Typography';
import Section from './ui/Section';
import Card from './ui/Card';
import { Button } from './ui/button';
import { useToast } from './ui/use-toast';

interface MainContentProps {
  onTeamSelect: (team: Team) => void;
  onTeamRequest: (request: string) => void;
}

const MainContent: React.FC<MainContentProps> = ({
  onTeamSelect: handleTeamSelect,
  onTeamRequest: handleTeamRequest,
}) => {
  const { toast } = useToast();
  const location = useLocation();

  useEffect(() => {
    // Check if we have email verification in the navigation state
    const state = location.state as { emailVerified?: boolean } | null;
    console.log('Navigation state:', state);
    
    if (state?.emailVerified) {
      console.log('Showing email verification toast');
      toast({
        title: "Email Verified Successfully!",
        description: "Your email has been confirmed. Welcome to FanFabric!",
        className: "bg-zinc-800 border border-orange-500/20 text-white [&_h2]:text-green-400",
        duration: 5000,
      });
      
      // Clear the state
      window.history.replaceState({}, '', location.pathname);
    }
  }, [location, toast]);

  return (
    <Section className="min-h-[calc(100vh-96px)] flex items-start pt-24">
      <div className="w-full max-w-4xl mx-auto px-4">
        <div className="text-center mb-12">
          <Typography
            variant="h1"
            className="text-4xl md:text-5xl font-bold text-white mb-6"
          >
            Find Your Team's Gear
          </Typography>
          <Typography
            variant="large"
            className="text-zinc-400 max-w-2xl mx-auto text-lg"
          >
            Support your local teams with custom fan merchandise
          </Typography>
        </div>

        <div className="grid gap-12 max-w-xl mx-auto relative mb-24">
          {/* Team Selector Card - Higher z-index */}
          <Card variant="hover" className="backdrop-blur-sm relative z-20">
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <Typography variant="h3" className="text-zinc-200">
                  Select Your Team
                </Typography>
                <span className="px-3 py-1 bg-orange-500/10 border border-orange-500/20 rounded-full text-orange-500 text-sm">
                  Featured
                </span>
              </div>
              <TeamSelector onSelectTeam={handleTeamSelect} />
            </div>
          </Card>

          {/* Team Request Card - Lower z-index */}
          <Card variant="hover" className="backdrop-blur-sm relative z-10">
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <Typography variant="h3" className="text-zinc-200">
                  Request Your Team
                </Typography>
                <Button variant="ghost" size="sm" className="text-zinc-400">
                  Optional
                </Button>
              </div>
              <div className="space-y-2">
                <Typography variant="body" className="text-zinc-400">
                  Don't see your team? Let us know and we'll add it to our
                  collection.
                </Typography>
                <TeamRequestForm onSubmit={handleTeamRequest} />
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-12 text-center">
          <Typography variant="body" className="text-zinc-500">
            Join thousands of fans supporting their local teams
          </Typography>
        </div>
      </div>
    </Section>
  );
};

export default MainContent;
