// src/App.tsx
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import MainContent from './components/MainContent';
import EmailVerificationMessage from './components/EmailVerificationMessage';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Contact from './pages/Contact';
import ResetPassword from './pages/ResetPassword';
import AdminLayout from './components/admin/layouts/AdminLayout';
import { Team } from './types/Team';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';
import { ROUTES } from './constants';
import './App.css';
import { Toaster } from "@/components/ui/toaster"
import AuthCallback from './components/AuthCallback';

// Lazy load admin components
const AdminDashboard = lazy(
  () => import('@/components/admin/pages/AdminDashboard')
);
const AdminTeams = lazy(() => import('@/components/admin/pages/AdminTeams'));
const AdminTeamDetails = lazy(
  () => import('@/components/admin/pages/AdminTeamDetails')
);
const AdminProducts = lazy(
  () => import('@/components/admin/pages/AdminProducts')
);
const AdminNewProduct = lazy(
  () => import('@/components/admin/pages/AdminNewProduct')
);
const AdminProductDetails = lazy(
  () => import('@/components/admin/pages/AdminProductDetails')
);
const AdminCategories = lazy(
  () => import('@/components/admin/pages/AdminCategories')
);
const AdminOrders = lazy(() => import('@/components/admin/pages/AdminOrders'));
const AdminOrderDetails = lazy(
  () => import('@/components/admin/pages/AdminOrderDetails')
);
const AdminUsers = lazy(() => import('@/components/admin/pages/AdminUsers'));
const AdminSettings = lazy(() => import('@/components/admin/pages/AdminSettings'));

// Lazy load team pages
const TeamListingPage = lazy(() => import('@/pages/teams/TeamListingPage'));
const TeamPage = lazy(() => import('@/pages/teams/TeamPage'));

// Configure query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false,
    },
  },
});

const LoadingFallback = () => (
  <div className="min-h-screen flex items-center justify-center">
    <LoadingSpinner size="lg" />
  </div>
);

function App() {
  const handleTeamSelect = (team: Team) => {
    console.log('Selected team:', team);
  };

  const handleTeamRequest = (request: string) => {
    console.log('Team request:', request);
  };

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <AuthProvider>
            <Router>
              <div className="App bg-zinc-900 text-white min-h-screen">
                <Routes>
                  {/* Auth Callback Route - No Header */}
                  <Route path="/auth/callback" element={<AuthCallback />} />
                  
                  {/* All other routes */}
                  <Route path="*" element={
                    <>
                      <Header />
                      <Routes>
                        {/* Public Routes */}
                        <Route path={ROUTES.HOME} element={<MainContent onTeamSelect={handleTeamSelect} onTeamRequest={handleTeamRequest} />} />
                        <Route path={ROUTES.LOGIN} element={<Login />} />
                        <Route path={ROUTES.ABOUT} element={<About />} />
                        <Route path={ROUTES.PRIVACY} element={<Privacy />} />
                        <Route path={ROUTES.CONTACT} element={<Contact />} />
                        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

                        {/* Protected Team Routes */}
                        <Route
                          path={ROUTES.TEAMS.LOCATION}
                          element={
                            <ProtectedRoute>
                              <Suspense fallback={<LoadingFallback />}>
                                <TeamListingPage />
                              </Suspense>
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path={ROUTES.TEAMS.TEAM}
                          element={
                            <ProtectedRoute>
                              <Suspense fallback={<LoadingFallback />}>
                                <TeamPage params={{ slug: '' }} />
                              </Suspense>
                            </ProtectedRoute>
                          }
                        />

                        {/* Protected Admin Routes */}
                        <Route
                          path="/admin/*"
                          element={
                            <ProtectedRoute>
                              <AdminLayout />
                            </ProtectedRoute>
                          }
                        >
                          <Route
                            index
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminDashboard />
                              </Suspense>
                            }
                          />
                          <Route
                            path="teams"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminTeams />
                              </Suspense>
                            }
                          />
                          <Route
                            path="teams/:teamId"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminTeamDetails />
                              </Suspense>
                            }
                          />
                          <Route
                            path="products"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminProducts />
                              </Suspense>
                            }
                          />
                          <Route
                            path="products/new"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminNewProduct />
                              </Suspense>
                            }
                          />
                          <Route
                            path="products/:productId"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminProductDetails />
                              </Suspense>
                            }
                          />
                          <Route
                            path="categories"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminCategories />
                              </Suspense>
                            }
                          />
                          <Route
                            path="orders"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminOrders />
                              </Suspense>
                            }
                          />
                          <Route
                            path="orders/:orderId"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminOrderDetails />
                              </Suspense>
                            }
                          />
                          <Route
                            path="users"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminUsers />
                              </Suspense>
                            }
                          />
                          <Route
                            path="settings"
                            element={
                              <Suspense fallback={<LoadingFallback />}>
                                <AdminSettings />
                              </Suspense>
                            }
                          />
                        </Route>
                      </Routes>
                    </>
                  } />
                </Routes>
                <EmailVerificationMessage />
                <Toaster />
              </div>
            </Router>
          </AuthProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
