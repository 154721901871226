import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-lg text-sm font-medium transition-all duration-200',
  {
    variants: {
      variant: {
        // Primary button - now with white text that changes to orange on hover
        // In button.tsx, update the primary variant:
        primary:
          'bg-zinc-800 border border-orange-500/40 text-orange-500/90 font-normal hover:text-orange-400 hover:bg-zinc-700/50 hover:border-orange-500/60',

        // Keep other variants the same...
        secondary:
          'bg-zinc-800/50 border border-zinc-600/30 text-zinc-300 hover:border-zinc-500/50 hover:bg-zinc-700/30',
        outline:
          'bg-transparent border border-zinc-600/30 text-zinc-300 hover:border-orange-500/30 hover:bg-zinc-800/50',
        destructive:
          'bg-zinc-800 border border-red-500/30 text-zinc-200 hover:border-red-500/50 hover:bg-zinc-700/50',
        ghost: 'text-zinc-400 hover:text-zinc-200 hover:bg-zinc-800/30',
        link: 'text-zinc-400 hover:text-orange-500 underline-offset-4 hover:underline',
      },
      size: {
        sm: 'h-8 px-3 text-xs',
        default: 'h-10 px-4',
        lg: 'h-12 px-6 text-base',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
