import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  FaHome,
  FaInfoCircle,
  FaEnvelope,
  FaLock,
  FaUser,
  FaShieldAlt,
} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import Logo from './Logo';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';

const MenuItem: React.FC<{
  label: string;
  icon: React.ReactNode;
  to: string;
}> = ({ label, icon, to }) => (
  <motion.li
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="py-2 px-4 cursor-pointer hover:bg-orange-700/30 rounded-md transition-colors duration-200 flex items-center"
  >
    <Link to={to} className="flex items-center">
      <span className="mr-3 text-orange-500">{icon}</span>
      <span className="text-lg font-medium">{label}</span>
    </Link>
  </motion.li>
);

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const { user, isAdmin, signOut } = useAuth();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);
  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-50 bg-zinc-800/50 backdrop-blur-md">
        <div className="w-full max-w-[2000px] mx-auto px-4 flex justify-between items-center py-4">
          <div className="flex items-center">
            <Logo />
            <span className="text-3xl font-bold ml-2">
              Fan<span className="text-orange-500">Fabric</span>
            </span>
          </div>
          <nav className="hidden sm:block">
            <ul className="flex space-x-6 items-center">
              <li>
                <Link
                  to="/"
                  className="hover:text-orange-500 transition-colors"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="hover:text-orange-500 transition-colors"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy"
                  className="hover:text-orange-500 transition-colors"
                >
                  Privacy
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-orange-500 transition-colors"
                >
                  Contact
                </Link>
              </li>
              {user && (
                <>
                  {isAdmin && (
                    <li>
                      <Link
                        to="/admin"
                        className="hover:text-orange-500 transition-colors"
                      >
                        Admin
                      </Link>
                    </li>
                  )}
                  <Button
                    onClick={signOut}
                    variant="primary"
                    className="px-6 py-2"
                  >
                    Logout
                  </Button>
                </>
              )}
              {!user && (
                <li>
                  <Link
                    to="/login"
                    className="hover:text-orange-500 transition-colors"
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          <button
            className="sm:hidden w-8 h-8 flex flex-col justify-center items-center"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span
              className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${isMenuOpen ? 'rotate-45 translate-y-1' : '-translate-y-0.5'}`}
            ></span>
            <span
              className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${isMenuOpen ? 'opacity-0' : 'opacity-100'}`}
            ></span>
            <span
              className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${isMenuOpen ? '-rotate-45 -translate-y-1' : 'translate-y-0.5'}`}
            ></span>
          </button>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="absolute top-full right-0 mt-2 w-48 bg-gradient-to-br from-zinc-800 to-zinc-900 rounded-xl shadow-lg overflow-hidden sm:hidden"
            >
              <motion.ul
                initial="closed"
                animate="open"
                variants={{
                  open: {
                    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
                  },
                  closed: {
                    transition: { staggerChildren: 0.05, staggerDirection: -1 },
                  },
                }}
                className="py-2"
              >
                <MenuItem label="Home" icon={<FaHome size={18} />} to="/" />
                <MenuItem
                  label="About"
                  icon={<FaInfoCircle size={18} />}
                  to="/about"
                />
                <MenuItem
                  label="Privacy"
                  icon={<FaShieldAlt size={18} />}
                  to="/privacy"
                />
                <MenuItem
                  label="Contact"
                  icon={<FaEnvelope size={18} />}
                  to="/contact"
                />
                {user ? (
                  <>
                    {isAdmin && (
                      <MenuItem
                        label="Admin"
                        icon={<FaLock size={18} />}
                        to="/admin"
                      />
                    )}
                    <li className="py-2 px-4">
                      <Button
                        onClick={signOut}
                        variant="primary"
                        className="w-full px-6 py-2"
                      >
                        Logout
                      </Button>
                    </li>
                  </>
                ) : (
                  <MenuItem
                    label="Login"
                    icon={<FaUser size={18} />}
                    to="/login"
                  />
                )}
              </motion.ul>
            </motion.div>
          )}
        </div>
      </header>
      <div className="h-[96px]"></div>
    </>
  );
};
export default Header;
