import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Team } from '../types/Team';
import { FaSearch, FaSpinner, FaArrowRight } from 'react-icons/fa';
import styles from './TeamSelector.module.css';
import { supabase } from '../lib/supabase';
import { Button } from './ui/button';
import { Input } from './ui/input';

interface TeamSelectorProps {
  onSelectTeam: (team: Team) => void;
}

const scrollToElement = (element: HTMLElement | null, offset: number = 0) => {
  if (!element) return;

  const elementRect = element.getBoundingClientRect();
  const absoluteElementTop = elementRect.top + window.pageYOffset;
  const middle = absoluteElementTop - window.innerHeight / 4;

  window.scrollTo({
    top: middle + offset,
    behavior: 'smooth',
  });
};

const TeamSelector: React.FC<TeamSelectorProps> = ({ onSelectTeam }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const shouldShowSearch = teams.length > 10;

  useEffect(() => {
    async function fetchTeams() {
      try {
        const { data, error } = await supabase
          .from('teams')
          .select('*')
          .eq('is_active', true)
          .order('city');

        if (error) throw error;
        setTeams(data || []);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    }
    fetchTeams();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputFocus = () => {
    setTimeout(() => {
      scrollToElement(containerRef.current, 100);
    }, 100);
  };

  const handleTeamSelect = (team: Team) => {
    setSelectedTeam(team);
    onSelectTeam(team);
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleAction = () => {
    if (selectedTeam) {
      const teamSlug = selectedTeam.team_name.toLowerCase().replace(/\s+/g, '-');
      const locationSlug = `${selectedTeam.city.toLowerCase()}-${selectedTeam.state.toLowerCase()}`.replace(/\s+/g, '-');
      navigate(`/products/${locationSlug}/${teamSlug}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const filteredTeams = shouldShowSearch
    ? teams.filter((team) =>
        `${team.city} ${team.team_name}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    : teams;

  if (loading)
    return (
      <div className={styles.loadingState}>
        <FaSpinner className="animate-spin text-orange-500 text-xl" /> Loading
        teams...
      </div>
    );
  if (error) return <div className={styles.errorState}>Error: {error}</div>;

  return (
    <div className="w-full" ref={containerRef}>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>Select Your Team</h2>
        <div className={styles.buttonWrapper}>
          {!isOpen || !shouldShowSearch ? (
            <Button
              variant="primary"
              className="w-full justify-start"
              onClick={() => {
                setIsOpen(true);
                handleInputFocus();
              }}
            >
              {selectedTeam
                ? `${selectedTeam.city} ${selectedTeam.team_name}`
                : 'Select your team!'}
            </Button>
          ) : (
            <div className={styles.searchContainer + ' relative'}>
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
              <Input
                ref={inputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search teams..."
                autoFocus={false}
                onFocus={handleInputFocus}
                className="pl-10"
              />
            </div>
          )}
          {isOpen && (
            <motion.div
              ref={dropdownRef}
              className={styles.dropdown}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              onKeyDown={handleKeyDown}
              tabIndex={0}
            >
              <div className={styles.teamGrid}>
                {filteredTeams.map((team) => (
                  <div
                    key={team.id}
                    className={styles.teamItem}
                    onClick={() => handleTeamSelect(team)}
                  >
                    {`${team.city} ${team.team_name}`}
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </div>
      </motion.div>

      <AnimatePresence>
        {selectedTeam && (
          <Button
            variant="primary"
            className="w-full mt-4"
            onClick={handleAction}
          >
            <span className="flex items-center justify-center gap-2">
              Take me there! <FaArrowRight />
            </span>
          </Button>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TeamSelector;
