import React from 'react';
import PageLayout from '../components/PageLayout';
import { FaInfoCircle } from 'react-icons/fa';

const About: React.FC = () => {
  return (
    <PageLayout title="About Us" icon={FaInfoCircle}>
      <div className="space-y-6">
        <p className="text-lg">
          Welcome to FanFabric, your go-to source for custom sports apparel
          designed to celebrate local high school teams and their fans! Founded
          with a deep appreciation for community spirit, we are dedicated to
          providing high-quality, personalized gear that allows you to proudly
          support your favorite teams.
        </p>
        <p className="text-lg">
          We proudly serve communities across New Mexico and beyond, offering
          customizable jerseys, shirts, and fan apparel tailored to showcase
          school pride with unique colors, mascots, and player details. Whether
          you're in the stands or cheering from home, our mission is to ensure
          that your fan experience is personalized, stylish, and easy.
        </p>
        <p className="text-lg">
          At FanFabric, we believe in creating affordable, high-quality apparel
          that reflects the passion of local sports communities. Our streamlined
          online shopping experience makes it simple for you to customize and
          order your gear, ensuring you can represent your school with pride.
        </p>
        <p className="text-lg">
          Join us in celebrating local high school teams with custom fan gear
          that stands out from the rest!
        </p>
      </div>
    </PageLayout>
  );
};

export default About;
