import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        console.log('Auth callback triggered');
        const params = new URLSearchParams(window.location.search);
        console.log('Current URL:', window.location.href);

        // Get error description from URL if it exists
        const errorDescription = params.get('error_description');
        if (errorDescription) {
          console.error('Auth error from URL:', errorDescription);
          navigate('/login?error=confirmation_failed');
          return;
        }

        // Handle the OAuth callback
        const { data, error } = await supabase.auth.getSession();
        console.log('Session data:', data);

        if (error) {
          console.error('Auth error:', error);
          navigate('/login?error=confirmation_failed');
          return;
        }

        if (data?.session) {
          console.log('Session established successfully');
          
          // Check if this is an email confirmation by looking for the code parameter
          const isEmailConfirmation = params.has('code');
          console.log('Is email confirmation:', isEmailConfirmation);

          if (isEmailConfirmation) {
            console.log('Redirecting with email verification flag');
            // Use navigate with state to ensure the parameter is passed correctly
            navigate('/', {
              replace: true,
              state: { emailVerified: true }
            });
          } else {
            navigate('/', { replace: true });
          }
        } else {
          console.error('No session established');
          navigate('/login?error=confirmation_failed');
        }
      } catch (error) {
        console.error('Callback handling error:', error);
        navigate('/login?error=confirmation_failed');
      }
    };

    handleCallback();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-4">Verifying Your Email...</h2>
        <p className="text-gray-600">Please wait while we complete the verification process.</p>
      </div>
    </div>
  );
};

export default AuthCallback;
