import * as React from 'react';
import { cn } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  success?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, success, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'w-full px-4 py-3 bg-zinc-900 rounded-xl leading-none text-white text-lg',
          'border border-orange-500/40 focus:border-orange-500/60',
          'focus:outline-none focus:ring-2 focus:ring-orange-500/20',
          'transition-all duration-300',
          'placeholder:text-zinc-400',
          error && 'border-red-500 focus:border-red-500 focus:ring-red-500/20',
          success && 'border-green-500 focus:border-green-500 focus:ring-green-500/20',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Input.displayName = 'Input';

export { Input };
